export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:1111';
export const APPSIGNAL_LOGS_KEY = process.env.REACT_APP_ENV_APPSIGNAL_LOGS_KEY || '000000';

export const REACT_APP_ENV_NAME = process.env.REACT_APP_ENV_NAME || 'unknown';

export const ROOT_ROUTE = '/olexa_app';

export const APP_VERSION = '0.1.19';

export const API_LOGIN = process.env.REACT_APP_ENV_API_LOGIN || 'olexapass';

export const API_PASSWORD = process.env.REACT_APP_ENV_API_PASSWORD || 'olexapass';

export const PING_INTERVAL_MIN = 4000; // 60000 = 1 minute
export const PING_INTERVAL_MAX = 25000; // 60000 = 1 minute

export const PING_COMMANDS = ['W/*WAW', 'W/*SAN', 'W/*LON', 'W/*PAR', 'W/*SWF', 'W/*NYC', 'W/*BKK', 'W/*HKG'];
export const AMADEUS_PING_COMMANDS = ['dacNRH', 'dacVMN', 'dacVZY', 'dacRLX', 'dacSMY', 'dacGGZ', 'dacQES']
export const PING_GDS_COMMAND_STATUS = 'ping';